import * as React from "react";

const Policy = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          fontFamily: "Arial",
          fontSize: 15,
          maxWidth: 800,
          textAlign: "left",
          margin: "0px auto",
        }}
      >
        <div>
          <img width="100%" src="/image/logobot.jpg" />
        </div>
        <div style={{ margin: "40px 0" }}>
          <div style={{ color: "#000" }}>
            <h1>https://rainbow.edu.vn Privacy Policys</h1>
            <div className="innerText">
              This privacy policy has been compiled to better serve those who are concerned with how
              their 'Personally Identifiable Information' (PII) is being used online. PII, as
              described in US privacy law and information security, is information that can be used
              on its own or with other information to identify, contact, or locate a single person,
              or to identify an individual in context. Please read our privacy policy carefully to
              get a clear understanding of how we collect, use, protect or otherwise handle your
              Personally Identifiable Information in accordance with our website.
              <br />
            </div>
            <span id="infoCo"></span>
            <br />
            <div className="grayText">
              <strong>
                What personal information do we collect from the people that visit our blog, website
                or app?
              </strong>
            </div>
            <br />
            <div className="innerText">
              We do not collect information from visitors of our site.
            </div>
            or other details to help you with your experience.
          </div>
          <br />
          <div className="grayText">
            <strong>When do we collect information?</strong>
          </div>
          <br />
          <div className="innerText">
            We collect information from you when you or enter information on our site.
          </div>
          <br />
          None <span id="infoUs"></span>
          <br />
          <div className="grayText">
            <strong>How do we use your information?</strong>
          </div>
          <br />
          <div className="innerText">
            {" "}
            We may use the information we collect from you when you register, make a purchase, sign
            up for our newsletter, respond to a survey or marketing communication, surf the website,
            or use certain other site features in the following ways:
            <br />
            <br />
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To personalize your experience and to
            allow us to deliver the type of content and product offerings in which you are most
            interested.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To improve our website in order to
            better serve you.
          </div>
          <span id="infoPro"></span>
          <br />
          <div className="grayText">
            <strong>How do we protect your information?</strong>
          </div>
          <br />
          <div className="innerText">
            Our website is scanned on a regular basis for security holes and known vulnerabilities
            in order to make your visit to our site as safe as possible.
            <br />
            <br />
          </div>
          <div className="innerText">
            We use regular Malware Scanning.
            <br />
            <br />
          </div>
          <div className="innerText">
            Your personal information is contained behind secured networks and is only accessible by
            a limited number of persons who have special access rights to such systems, and are
            required to keep the information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL) technology.
          </div>
          <br />
          <div className="innerText">
            We implement a variety of security measures when a user enters, submits, or accesses
            their information to maintain the safety of your personal information.
          </div>
          <br />
          <div className="innerText">
            All transactions are processed through a gateway provider and are not stored or
            processed on our servers.
          </div>
          <span id="coUs"></span>
          <br />
          <div className="grayText">
            <strong>Do we use 'cookies'?</strong>
          </div>
          <br />
          <div className="innerText">We do not use cookies for tracking purposes</div>
          <div className="innerText">
            <br />
            You can choose to have your computer warn you each time a cookie is being sent, or you
            can choose to turn off all cookies. You do this through your browser settings. Since
            browser is a little different, look at your browser's Help Menu to learn the correct way
            to modify your cookies.
            <br />
          </div>
          <br />
          <div className="innerText">
            If you turn cookies off, some features will be disabled. that make your site experience
            more efficient and may not function properly.
          </div>
          <br />
          <div className="innerText">However, you will still be able to place orders .</div>
          <br />
          <span id="trDi"></span>
          <br />
          <div className="grayText">
            <strong>Third-party disclosure</strong>
          </div>
          <br />
          <div className="innerText">
            We do not sell, trade, or otherwise transfer to outside parties your Personally
            Identifiable Information unless we provide users with advance notice. This does not
            include website hosting partners and other parties who assist us in operating our
            website, conducting our business, or serving our users, so long as those parties agree
            to keep this information confidential. We may also release information when it's release
            is appropriate to comply with the law, enforce our site policies, or protect ours or
            others' rights, property or safety. <br />
            <br /> However, non-personally identifiable visitor information may be provided to other
            parties for marketing, advertising, or other uses.
          </div>
          <span id="trLi"></span>
          <br />
          <div className="grayText">
            <strong>Third-party links</strong>
          </div>
          <br />
          <div className="innerText">
            Occasionally, at our discretion, we may include or offer third-party products or
            services on our website. These third-party sites have separate and independent privacy
            policies. We therefore have no responsibility or liability for the content and
            activities of these linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </div>
          <span id="gooAd"></span>
          <br />
          <div className="blueText">
            <strong>Google</strong>
          </div>
          <br />
          <div className="innerText">
            Google's advertising requirements can be summed up by Google's Advertising Principles.
            They are put in place to provide a positive experience for users.
            https://support.google.com/adwordspolicy/answer/1316548?hl=en <br />
            <br />
          </div>
          <div className="innerText">
            We have not enabled Google AdSense on our site but we may do so in the future.
          </div>
          <div className="innerText">facebook</div>
          <span id="coppAct"></span>
          <br />
          <div className="blueText">
            <strong>COPPA (Children Online Privacy Protection Act)</strong>
          </div>
          <br />
          <div className="innerText">
            When it comes to the collection of personal information from children under the age of
            13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in
            control. The Federal Trade Commission, United States' consumer protection agency,
            enforces the COPPA Rule, which spells out what operators of websites and online services
            must do to protect children's privacy and safety online.
            <br />
            <br />
          </div>
          <div className="innerText">
            We market to
            <div className="innerText">We do not collect information from children under 13</div>
            children under 13.
          </div>
          <div className="innerText">No</div>
          <div className="innerText">
            <br />
            <strong>
              In order to remove your child's information please contact the following personnel:{" "}
            </strong>
          </div>
          <div className="innerText">
            <br />
            <strong>We adhere to the following COPPA tenants: </strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Parents can review, delete, manage or
            refuse with whom their child's information is shared through contacting us directly.
          </div>
          or contacting us directly.
          <br />
          <span id="ftcFip"></span>
          <br />
          <div className="blueText">
            <strong>Fair Information Practices</strong>
          </div>
          <br />
          <div className="innerText">
            The Fair Information Practices Principles form the backbone of privacy law in the United
            States and the concepts they include have played a significant role in the development
            of data protection laws around the globe. Understanding the Fair Information Practice
            Principles and how they should be implemented is critical to comply with the various
            privacy laws that protect personal information.
            <br />
            <br />
          </div>
          <div className="innerText">
            <strong>
              In order to be in line with Fair Information Practices we will take the following
              responsive action, should a data breach occur:
            </strong>
          </div>
          <div className="innerText">We will notify the users via in-site notification</div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Within 7 business days
          </div>
          <div className="innerText">
            <br />
            We also agree to the Individual Redress Principle which requires that individuals have
            the right to legally pursue enforceable rights against data collectors and processors
            who fail to adhere to the law. This principle requires not only that individuals have
            enforceable rights against data users, but also that individuals have recourse to courts
            or government agencies to investigate and/or prosecute non-compliance by data
            processors.
          </div>
          <span id="canSpam"></span>
          <br />
          <div className="blueText">
            <strong>CAN SPAM Act</strong>
          </div>
          <br />
          <div className="innerText">
            The CAN-SPAM Act is a law that sets the rules for commercial email, establishes
            requirements for commercial messages, gives recipients the right to have emails stopped
            from being sent to them, and spells out tough penalties for violations.
            <br />
            <br />
          </div>
          <div className="innerText">
            <strong>We collect your email address in order to:</strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send information, respond to
            inquiries, and/or other requests or questions
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Process orders and to send information
            and updates pertaining to orders.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send you additional information
            related to your product and/or service
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Market to our mailing list or continue
            to send emails to our clients after the original transaction has occurred.
          </div>
          <div className="innerText">
            <br />
            <strong>To be in accordance with CANSPAM, we agree to the following:</strong>
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Not use false or misleading subjects
            or email addresses.
          </div>
          <h2>Data Deletion Request Policy Update</h2>
          <ol>
            <li>
              <p>
                <strong>Requesting Data Deletion:</strong> Users have the right to request the
                deletion of their personal data held by RAINBOW. To initiate the data deletion
                process, please submit a formal request via email to{" "}
                <a href="mailto:contact@rainbow.edu.vn">contact@rainbow.edu.vn</a>.
              </p>
            </li>
            <li>
              <p>
                <strong>Request Submission Guidelines:</strong> Data deletion requests must be
                submitted from the email address associated with the user's RAINBOW account. The
                subject line of the email should be "Data Deletion Request."
              </p>
            </li>
            <li>
              <p>
                <strong>Required Information:</strong> In the body of the email, users must provide
                the following details:
              </p>
              <ul>
                <li>Full Name</li>
                <li>User ID or Account Number</li>
                <li>
                  A clear and specific description of the data or categories of data to be deleted.
                </li>
              </ul>
              <p></p>
            </li>
            <li>
              <p>
                <strong>Verification Process:</strong> For security purposes, RAINBOW reserves the
                right to verify the identity of the user initiating the data deletion request. Users
                may be required to provide additional information or undergo a verification process.
              </p>
            </li>
            <li>
              <p>
                <strong>Processing Time:</strong> RAINBOW is committed to processing data deletion
                requests in a timely manner. Users will receive a confirmation email once the data
                deletion process is completed.
              </p>
            </li>
            <li>
              <p>
                <strong>Legal and Regulatory Considerations:</strong> Users should be aware that
                certain legal or regulatory obligations may necessitate the retention of specific
                information for a defined period. In such cases, users will be informed of the
                reasons for data retention.
              </p>
            </li>
            <li>
              <p>
                <strong>Contact Information:</strong> For any inquiries or concerns related to data
                deletion requests or our privacy practices, users can contact our dedicated privacy
                team at <a href="mailto:contact@rainbow.edu.vn">contact@rainbow.edu.vn</a>.
              </p>
            </li>
          </ol>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Identify the message as an
            advertisement in some reasonable way.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Include the physical address of our
            business or site headquarters.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Monitor third-party email marketing
            services for compliance, if one is used.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Honor opt-out/unsubscribe requests
            quickly.
          </div>
          <div className="innerText">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Allow users to unsubscribe by using
            the link at the bottom of each email.
          </div>
          <div className="innerText">
            <strong>
              <br />
              If at any time you would like to unsubscribe from receiving future emails, you can
              email us at
            </strong>
          </div>
          <div className="innerText">
            contact@cubetech.vn and we will promptly remove you from <strong>ALL</strong>{" "}
            correspondence.
          </div>
          <br />
          <span id="ourCon"></span>
          <br />
          <div className="blueText">
            <strong>Contacting Us</strong>
          </div>
          <br />
          <div className="innerText">
            If there are any questions regarding this privacy policy, you may contact us using the
            information below.
            <br />
            <br />
          </div>
          <div className="innerText">https://rainbow.edu.vn</div>
          Lam Dong, (+84)2633 683 686 <div className="innerText">VietNam</div>
          <div className="innerText">contact@rainbow.edu.vn</div>
          <div className="innerText">
            <br />
            Last Edited on 2024-05-22
          </div>
        </div>

        <div>
          <img width="100%" src="/image/logobot.jpg" />
        </div>
      </div>
    </div>
  );
};
export default Policy;
